<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-transactions-purchase-invoice-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.purchase.invoice.actions.createInv') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table small
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-transactions-purchase-invoice-prints', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: code -->
      <template #cell(image)="{item}">
        <div v-if="item.name_file !== null">
        <b-link
          :to="{ name: 'apps-transactions-purchase-invoice-listImage', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
           <feather-icon
            icon="FileTextIcon"
            size="21"
          />
          </span>
        </b-link>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <!-- Column: Lab -->
      <template #cell(label)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.contact.label }}
        </span>
        <small class="font-italic">{{ item.remark }}</small>
      </template>

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{
            formatCurrency(item.sum_of_bill_amount)
          }}
        </span>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div class="d-flex">
          <b-badge v-for="(tag, index) in item.tags" :key="index" pill variant="light-primary">{{ tag.name }}</b-badge>
        </div>
      </template>

      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

      <!-- Column: actions -->
      <template #cell(actions)="{item}">
        <div>
        <b-link
        @click="downloadPDF(item.id)"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
           <feather-icon
            icon="FileTextIcon"
            size="21"
          />
          </span>
        </b-link>
        </div>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import jsPDF from 'jspdf' 
import moment from 'moment'
import angkaTerbilang from '@develoka/angka-terbilang-js'
import { formatCurrency } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'
// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge
  },
  setup () {
    const { $t } = useLocalization()
    const { $get } = useHttp()

    const invoice = ref({
      company: {
        name: ''
      },
      contact: {
        pic_name: ''
      },
      payments: [
        {
          master: {
            code: '',
            description: ''
          }
        }
      ]
    })

    // Table Handlers
    const tableColumns = [
      { key: 'code', label: '#' },
      { key: 'image', label: $t('globalSingular.document'), thClass: 'text-center', tdClass: 'text-center' },
      { key: 'date', label: $t('globalSingular.date'), sortable: true  },
      { key: 'contact.label', label: $t('globalSingular.vendor'), sortable: true  },
      { key: 'office.name', label: $t('globalSingular.branch'), sortable: true  },
      { key: 'remark', label: $t('globalSingular.description') },
      { key: 'amount', label: $t('globalSingular.amount'), thClass: 'text-right', tdClass: 'text-right' },
      { key: 'tags', label: $t('globalSingular.tags') },
      { key: 'audit', label: $t('globalSingular.audit') },
      { key: 'actions', label: $t('globalSingular.actions'), thClass: 'text-center', tdClass: 'text-center' }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'remark',
      'contact'
    ]

    const getDataPreview = async (id) => {
      const { data } =  await $get({
        url: `transaction/purchase/invoice/${id}`
      })
      invoice.value = data
    }
    
    const downloadPDF = async(id) =>{
      await getDataPreview(id)
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: 'a4'
      });

      doc.setProperties({
        title: "Purchase Invoice"
      });

      let xPos = 10
      let yPos = 10

      let xReactPos = 10
      let yReactPos = 10

      //revisi
      doc.rect(xReactPos + 140, yReactPos + 6, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 6, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("Revisi", xReactPos + 142, yReactPos + 10)
      doc.text("03", xReactPos + 166, yReactPos + 10)

      // tanggal
      doc.rect(xReactPos + 140, yReactPos + 11, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 11, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("Tanggal", xReactPos + 142, yReactPos + 15)
      doc.text("01/03/2015", xReactPos + 166, yReactPos + 15)

      // No
      doc.rect(xReactPos + 140, yReactPos + 16, 24, 5)
      doc.rect(xReactPos + 164, yReactPos + 16, 24, 5)
      doc.setFontSize(9).setFont('','')
      doc.text("No", xReactPos + 142, yReactPos + 20)
      doc.text("FORM-PRC-03", xReactPos + 166, yReactPos + 20)

      doc.setFontSize(10).setFont('','bold')
      //Company name
      doc.text("PT. Pandu Siwi Sentosa", xPos, yPos)
      doc.setFontSize(8).setFont('','')
      yPos += 4
      //company address
      doc.text("Jalan Raya Bekasi Timur KM 18 no 30", xPos, yPos)
      yPos += 4
      doc.text("Jakarta Timur 13930", xPos, yPos)
      doc.setFontSize(10).setFont('','bold')
      yPos += 4
      doc.text("Purchase Departement", xPos, yPos)
      doc.setFontSize(8).setFont('','')
      yPos += 4
      doc.text("Telp : (021) 460 2993 ext 128", xPos, yPos)
      yPos += 4
      doc.text("Fax  : (021) 460 7625", xPos, yPos)

      doc.setFontSize(20).setFont('','bold')
      xPos += 70
      yPos += 10
      doc.text("Purchase Invoice", xPos, yPos)

      //name to 1
      doc.setFontSize(8).setFont('','bold')
      xPos -= 70
      yPos += 10
      doc.text("Kepada Yth", xPos, yPos)

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos)
      
      xPos += 5
      doc.text(invoice.value.contact.brand, xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 75, yPos)
      
      //name to 2
      yPos += 5
      doc.text("", xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 75, yPos)

      //telp
      doc.setFontSize(8).setFont('','bold')
      xPos -= 25
      yPos += 5
      doc.text("No Telp", xPos, yPos);

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos);
      
      xPos += 5
      doc.text(invoice.value.contact.pic_handphone, xPos, yPos);
      yPos += 1
      doc.line(xPos, yPos, 75, yPos)

      //tanggal
      doc.setFontSize(8).setFont('','bold')
      xPos += 100
      yPos -= 13
      doc.text("Tanggal", xPos, yPos);

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos);
      
      xPos += 5
      doc.text(moment(invoice.value.date, "DD/MM/YYYY").format('LL'), xPos, yPos);
      yPos += 1
      doc.line(xPos, yPos, 200, yPos)

      //No PO
      doc.setFontSize(8).setFont('','bold')
      xPos -= 25
      yPos += 5
      doc.text("No. PI", xPos, yPos)

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos)
      
      console.log(invoice)
      xPos += 5
      doc.text(invoice.value.code, xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 200, yPos)

      //No. Fax
      doc.setFontSize(8).setFont('','bold')
      xPos -= 25
      yPos += 5
      doc.text("No. Fax", xPos, yPos)

      doc.setFontSize(8).setFont('','')
      xPos += 20
      doc.text(":", xPos, yPos)
      
      xPos += 5
      doc.text("021 - 6415263", xPos, yPos)
      yPos += 1
      doc.line(xPos, yPos, 200, yPos)

      
      doc.setFontSize(9).setFont('','bold')
      // No
      doc.rect(xReactPos, yReactPos + 65, 10, 5)
      doc.text("No.", xReactPos + 2, yReactPos + 68)
      doc.rect(xReactPos, yReactPos + 70, 10, 110)

      // spesifikasi
      doc.rect(xReactPos + 10, yReactPos + 65, 70, 5)
      doc.text("Spesifikasi Barang / Jasa", xReactPos + 30, yReactPos + 68)
      doc.rect(xReactPos + 10, yReactPos + 70, 70, 110)
      // Jumlah
      doc.rect(xReactPos + 80, yReactPos + 65, 24, 5)
      doc.text("Jumlah", xReactPos + 86, yReactPos + 68)
      doc.rect(xReactPos + 80, yReactPos + 70, 24, 110)
      // satuan
      doc.rect(xReactPos + 104, yReactPos + 65, 24, 5)
      doc.text("Satuan", xReactPos + 110, yReactPos + 68)
      doc.rect(xReactPos + 104, yReactPos + 70, 24, 110)
      // harga satuan
      doc.rect(xReactPos + 128, yReactPos + 65, 32, 5)
      doc.text("Harga Satuan", xReactPos + 135, yReactPos + 68)
      doc.rect(xReactPos + 128, yReactPos + 70, 32, 110)
      // Total harga
      doc.rect(xReactPos + 160, yReactPos + 65, 32, 5)
      doc.text("Total Harga", xReactPos + 167, yReactPos + 68)
      doc.rect(xReactPos + 160, yReactPos + 70, 32, 110)

      let xLoopPos = 10
      let yLoopPos = 79
      let hargaSatuan = 0
      let dpp = 0
      let ppn = 0
      let biayaLainLain = 0 
      console.log(invoice.value)

      invoice.value.details.forEach( async (field, index) => {
        dpp = dpp + (field.product.is_miscellaneous_expense ? 0 : Number(field.base_amount))
        ppn = ppn + (field.product.is_miscellaneous_expense ? 0 : Number(field.tax_amount))
        biayaLainLain = biayaLainLain + (field.product.is_miscellaneous_expense ? Number(field.bill_amount) : 0 )
        let quantitys = Number(field.quantity).toString()

        yLoopPos+=5
        doc.text(`${index+1}`, xLoopPos + 3.5, yLoopPos)
        doc.text(field.product.name, xLoopPos + 12, yLoopPos)
        doc.text(quantitys, xLoopPos + 90, yLoopPos)
        doc.text(invoice.value.detailsUom[index].uom.uom.toString(), xLoopPos + 113, yLoopPos)
        hargaSatuan = ((field.amount ?? 0) / (field.product.quantity ?? 1))
        doc.text(isNaN(hargaSatuan) ? formatCurrency(0) : formatCurrency(hargaSatuan), xLoopPos + 130, yLoopPos)
        doc.text(formatCurrency(field.base_amount ?? 0), xLoopPos + 162, yLoopPos)
      })
      
 let grantTotal = dpp + ppn + biayaLainLain
      doc.rect(xReactPos, yReactPos + 180, 128, 18)
      
      doc.setFontSize(9).setFont('','bold')
      doc.rect(xReactPos + 128, yReactPos + 180, 32, 6)
      doc.text("DPP", xReactPos + 130, yReactPos + 184)
      
      doc.rect(xReactPos + 128, yReactPos + 186, 32, 6)
      doc.text("PPN", xReactPos + 130, yReactPos + 190)
      
      doc.rect(xReactPos + 128, yReactPos + 192, 32, 6)
      doc.text("Biaya Lain-Lain", xReactPos + 130, yReactPos + 196)

      doc.rect(xReactPos + 128, yReactPos + 198, 32, 6)
      doc.text("Grand Total", xReactPos + 130, yReactPos + 202)

      doc.rect(xReactPos + 160, yReactPos + 180, 32, 6)
      doc.text(`${formatCurrency(dpp)}`, xReactPos + 162, yReactPos + 184)

      doc.rect(xReactPos + 160, yReactPos + 186, 32, 6)
      doc.text(`${formatCurrency(ppn)}`, xReactPos + 162, yReactPos + 190)

      doc.rect(xReactPos + 160, yReactPos + 192, 32, 6)
      doc.text(`${formatCurrency(biayaLainLain)}`, xReactPos + 162, yReactPos + 196)

      doc.rect(xReactPos + 160, yReactPos + 198, 32, 6)
      doc.text(`${formatCurrency(grantTotal)}`, xReactPos + 162, yReactPos + 202)
      
      doc.rect(xReactPos, yReactPos + 204, 192, 6)
      doc.text(`Terbilang                : ${angkaTerbilang(Intl.NumberFormat().format(invoice.value.sum_of_bill_amount).replaceAll(',', ''))+ ' rupiah'}`, xReactPos + 1, yReactPos + 207)

      doc.rect(xReactPos, yReactPos + 210, 192, 6)
      doc.text(`Cara Pembayaran : Tempo Satu Bulan`, xReactPos + 1, yReactPos + 213)
      
      //keterangan
      doc.text(`Keterangan : ..............................................................................................................`, xReactPos + 1, yReactPos + 219)

      //Catatan
      doc.text(`Catatan : `, xReactPos + 1, yReactPos + 222)

      doc.text(`1.  Mohon kirimkan barang/jasa sebelum tanggal                               ............`, xReactPos + 6, yReactPos + 229)

      doc.text(`2.  Cantumkan No. PO didalam tagihan/invoice dan surat jalan.`, xReactPos + 6, yReactPos + 234)
      
      doc.text(`3.  Mohon barang/jasa tersebut diatas dikirim/dikerjakan ke : `, xReactPos + 6, yReactPos + 240)
      
      doc.text(`    Alamat             :`, xReactPos + 6, yReactPos + 246)
      doc.line(xReactPos + 32, yReactPos + 246, 117, yReactPos + 246)
      
      doc.line(xReactPos + 32, yReactPos + 252, 117, yReactPos + 252)

      doc.text(`    PIC & No telp :`, xReactPos + 6, yReactPos + 258)
      doc.line(xReactPos + 32, yReactPos + 258, 117, yReactPos + 258)

      doc.text(`    No PO               :`, xReactPos + 6, yReactPos + 264)
      // doc.text(`${invoice.value.getPO[0].getNomor.code}`, xReactPos + 32, yReactPos + 264)
      doc.line(xReactPos + 32, yReactPos + 265, 117, yReactPos + 265)

      //tanda tangan
      doc.rect(xReactPos + 123, yReactPos + 216, 70, 6)
      doc.text(`Jakarta, ${moment().format('LL')}`, xReactPos + 142, yReactPos + 220)
      doc.rect(xReactPos + 123, yReactPos + 222, 70, 30)
      doc.text(`Mohd Azlan`, xReactPos + 150, yReactPos + 250)
      doc.rect(xReactPos + 123, yReactPos + 252, 70, 6)
      doc.text(`President Director`, xReactPos + 146, yReactPos + 256)
      // doc.output('dataurlnewwindow');
      await doc.save(`${invoice.value.code}.pdf`, {returnPromise: true})

    }
    
    return {
      formatCurrency,
      tableColumns,
      columnToBeFilter,
      downloadPDF,
      ...useListTable({
        url: 'transaction/purchase/invoice'
      })
    }
  }
}
</script>
